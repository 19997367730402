import RawHtml from '@components/RawHtml';
import AngleRight from '@icons/angle-right.svg';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

type Props = {
  title: string;
  text: string;
};

function Accordion(props: Props) {
  const { title, text } = props;

  return (
    <AccordionPrimitive.Item className='accordion' value={title}>
      <AccordionPrimitive.Header className='accordion__header'>
        <AccordionPrimitive.Trigger className='accordion__trigger'>
          <span className='accordion__icon' aria-hidden='true'>
            <AngleRight height={50} />
          </span>
          <span className='accordion__title'>{title}</span>
        </AccordionPrimitive.Trigger>
      </AccordionPrimitive.Header>
      <AccordionPrimitive.Content className='accordion__content' forceMount>
        <RawHtml html={text} />
      </AccordionPrimitive.Content>
    </AccordionPrimitive.Item>
  );
}

export default Accordion;
