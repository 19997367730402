'use client';

import RawHtml from '@components/RawHtml';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import Link from 'next/link';

type Product = {
  title: string;
  kurs: string;
};

type Course = {
  text: string;
  product: Product;
};

type Props = { title: string; text: string; products: Course[]; anchor_id: string };

function ProductPresentation(props: Props) {
  const { title, text, products, anchor_id } = props;

  return (
    <div className='product-presentation streamfield'>
      <h2 className='product-presentation__title'>{title}</h2>
      <RawHtml html={text} />
      <AccordionPrimitive.Root className='product-presentation__accordion' type='multiple'>
        {products.map(product => (
          <AccordionPrimitive.Item
            className='accordion'
            key={product.product.kurs}
            value={product.product.title}
          >
            <AccordionPrimitive.Header className='accordion__header'>
              <AccordionPrimitive.Trigger className='accordion__trigger'>
                {product.product.title}
                <span className='accordion__icon-container'>
                  <span className='accordion__icon' aria-hidden='true'>
                    <span />
                    <span />
                  </span>
                </span>
              </AccordionPrimitive.Trigger>
            </AccordionPrimitive.Header>
            <AccordionPrimitive.Content className='accordion__content' forceMount>
              <RawHtml html={product.text} />
              <Link
                className='button button--primary button--product-presentation'
                href={`?course=${product.product.kurs}#${anchor_id}`}
              >
                Freien Termin finden
              </Link>
            </AccordionPrimitive.Content>
          </AccordionPrimitive.Item>
        ))}
      </AccordionPrimitive.Root>
    </div>
  );
}

export default ProductPresentation;
