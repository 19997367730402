'use client';

import RawHtml from '@components/RawHtml';
import React, { useRef } from 'react';
import ArrowDownIcon from '@icons/arrow-down.svg';

type Props = { value: string };

function ReadMore(props: Props) {
  const { value } = props;
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className='streamfield read-more' ref={ref}>
      <RawHtml html={value} />
      <div className='read-more__overlay'>
        <button
          className='read-more__overlay-button'
          onClick={() => ref.current?.classList.toggle('read-more--extended')}
        >
          Weiterlesen <ArrowDownIcon />
        </button>
      </div>
    </div>
  );
}

export default ReadMore;
