'use client';

import RawHtml from '@components/RawHtml';
import clsx from 'clsx';
import { useState } from 'react';

type Props = { text: string };

function ReviewText(props: Props) {
  const { text } = props;
  const [isExtended, setIsExtended] = useState(false);

  const paragraphs = text.split('</p>');
  const firstParagraph = `${paragraphs[0]}</p>`;
  const restOfText = `<div class="extended__text">${text.substring(text.indexOf('/') + 3)}</div>`;

  return (
    <div className={clsx('review__text__wrapper', { extended: isExtended })}>
      <RawHtml
        html={firstParagraph + restOfText}
        className={clsx('review__text', { extended: isExtended })}
      />
      {paragraphs.length > 2 ? (
        <button
          className='review__read-more button--primary'
          onClick={() => (isExtended ? setIsExtended(false) : setIsExtended(true))}
          aria-label={isExtended ? 'Kundenfeedback einklappen' : 'Kundenfeedback ausklappen'}
        >
          {isExtended ? 'Weniger' : 'Weiterlesen'}
        </button>
      ) : null}
    </div>
  );
}

export default ReviewText;
